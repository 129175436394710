@import '../../../../sass/variables.scss';

.css-o943dk-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: $white-color !important;
    font-weight: normal;
    font-family: 'Junts' !important;
}

.css-gjbq6i-MuiInputBase-root-MuiFilledInput-root:before {
    border-bottom: 1px solid $second-color !important;
}

.css-10botns-MuiInputBase-input-MuiFilledInput-input {
    color: $white-color !important;
}

.css-1yhd05c-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-1yhd05c-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
    color: #ffffff !important;
}
