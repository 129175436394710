@import '/src/sass/variables.scss';

.cercador-input {
  width: 100%;
  padding: 5px;

  .cercador-input__form {
    border: 2px solid $gris-color;
    border-radius: 15px;
    display: flex;
    align-items: center;
    width: 100%;
    transition: all 0.3s ease-in-out;

    button {
      background-color: transparent;
      color: $title-color;
      padding: 0;
      padding-left: 10px;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: $main-color;
      }
    }

    input {
      padding: 10px;
      border: none;
      width: 100%;
      border-radius: 15px;

      &:focus {
        outline: none;
      }
    }
  }
}

.cercador-input__form--focused {
  border-color: $main-color !important;
}
