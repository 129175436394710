@import '../../../../../sass/variables.scss';

.footer__container {
    align-items: center;
    display: flex;
    flex-direction: column;
    background-color: $main-color;
    padding: 0 $main-padding;
    overflow: hidden;

    .afiliat {
        background-color: $title-color;
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5rem 0;
    }

    .footer__content {
        padding: 3rem 0;
        width: 100%;
    }

    .disclaimer__container {
        display: flex;
        width: 100%;
        flex-direction: column;
        padding-bottom: 2rem;
        h2 {
            margin: 5px;
            color: #fff;
            font-weight: 200;
            font-size: 1rem;
            text-align: left;
        }
    }
}

/************************/
/************************/
/* PHONE MEDIA QUERIES */
@media (max-width: 575.98px) {
    .footer__container {
        padding: 2rem 0;

        .afiliat {
            padding: 3rem 0;
        }

        .footer__content {
            padding: 0;
        }

        .disclaimer__container {
            width: 100%;
            padding: 0;

            h2 {
                font-size: 0.85rem;
                text-align: center;
            }

            .disclaimer-phone {
                display: flex;
                justify-content: center;
                gap: 10px;
                h2 {
                    text-align: center;
                }
            }
        }
    }
}

/************************/
/************************/
/* TABLE MEDIA QUERIES */
@media (min-width: 768px) and (max-width: 1024px) {
    .footer__container {
        padding: 0 1rem;

        .afiliat {
            width: 100vw;
        }
    }
}
