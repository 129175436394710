@import '/src/sass/variables.scss';

.afiliat__container {
  .title__section {
    margin-top: 0 !important;
    h2 {
      color: $white-color;
    }
  }

  .afiliat__form {
    form {
      display: flex;
      justify-content: center;
      gap: 3rem;
      .afiliat__form__fields {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .inputfield__field {
          .Mui-error {
            .MuiFilledInput-input {
              border: 2px solid $red-color !important;
            }

            &::after {
              border-bottom: 2px solid $red-color;
            }
          }

          .Mui-focused {
            .MuiFilledInput-input {
              border: 2px solid $main-color !important;
            }

            &::after {
              border-bottom: 3px solid $main-color;
            }
          }

          .MuiFilledInput-input {
            border: 2px solid #bacdd2 !important;
          }
          label {
            font-family: 'Junts', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
              'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            color: $white-color;
            font-weight: 200 !important;
          }

          input {
            color: $white-color !important;
          }
        }
      }

      .afiliat__form__legal {
        .afiliat__form__legal--checkbox-label {
          color: #fff;
          font-weight: 200;
          font-size: 0.9rem;
          margin: 0;
        }

        button {
          margin-top: 1rem;
        }
      }

      .afiliat__donation__button {
        button {
          background-color: transparent;
          border: 1px solid $white-color;
        }
      }
    }
  }
}

// ***
// ****
// phone media query
@media (max-width: 575.98px) {
  .afiliat__container {
    padding: $main-padding-mobile;

    .title__section {
      margin-top: 0 !important;
    }

    .afiliat__form {
      form {
        flex-direction: column;
      }
    }
  }
}

// ***
// ****
// tablet media query
@media (min-width: 768px) and (max-width: 1024px) {
  .afiliat__container {
    padding: $main-padding-mobile;
  }
}
