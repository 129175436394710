@import '../../../sass/variables.scss';

.modelone__header {
    height: 400px;
    position: relative;
    background-size: cover !important;
    .layer {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.433);
        align-items: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 2rem;
        h1 {
            color: $white-color;
        }
    }
}

.model__page__content {
    padding: $main-padding $main-padding;
}

/************************/
/************************/
/* PHONE MEDIA QUERIES */
@media (max-width: 575.98px) {
    .modelone__header {
        height: 200px;

        .layer {
            h1 {
                font-size: 2.5rem;
                text-align: center;
            }
        }
    }

    .model__page__content {
        padding: $main-padding-mobile;
    }
}

/************************/
/************************/
/* TABLET MEDIA QUERIES */
@media (min-width: 768px) and (max-width: 1024px) {
    .modelone__header {
        height: 300px;
    }

    .model__page__content {
        padding: $main-padding-mobile;
    }
}
