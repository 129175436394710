@import '../../../../sass/variables.scss';

.link-active {
    h2 {
        border-color: $title-color;
    }
}

.menu__container {
    overflow: hidden !important;
    padding: 0.4rem;
    .menu__container--wrap {
        position: relative;
        width: 100%;
        height: 90vh;
    }

    .menu__items {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-bottom: 1.5rem;
        h2,
        h3 {
            text-align: right;
            color: $title-color;
            font-size: 1.3rem;
            transition: all 0.3s;
            font-weight: 500;
        }

        a {
            display: flex;
            justify-content: flex-end;
        }

        .menu__items--rare {
            display: flex;
            flex-direction: column;
            gap: 0.25rem;
            transition: all 0.3s;

            h2 {
                color: $main-color;
                font-size: 1.2rem;
                font-weight: 300;

                &:hover {
                    color: $title-color;
                }
            }
        }
    }

    .menu__bottom {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        gap: 2rem;
        position: absolute;
        bottom: 0;
        right: 0;

        .menu_bottom--buttons {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: flex-end;
            gap: 1rem;
            .afiliatbutton__container {
                button {
                    font-size: 1.3rem;
                    padding: 10px 20px;
                    border: 3px solid $main-color;
                }
            }
        }

        .socialicons__container {
            .socialicons__icon {
                color: #fff;
                background-color: $main-color;

                &:hover {
                    background-color: $white-color;
                    color: $main-color;
                }
            }
        }
    }
}

/************************/
/************************/
/* PHONE MEDIA QUERIES */
@media (max-width: 575.98px) {
    .menu__container {
        .menu__items {
            display: flex;
            flex-direction: column;
            gap: 0.5rem !important;

            h2 {
                font-size: 1.25rem;
            }
        }

        .menu__bottom {
            .afiliatbutton__container {
                button {
                    padding: 10px 10px !important;
                    border: 3px solid $main-color;
                }
            }
        }
    }
}
