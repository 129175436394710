@import '/src/sass/variables.scss';

.footercontent__container {
    display: flex;
    justify-content: space-between;

    .footercontent--right {
        margin-left: 3rem;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 6rem;
        align-items: center;
    }

    h2 {
        color: $white-color;
        font-size: 1rem;
        font-weight: 200;

        &:hover {
            color: $title-color;
            cursor: pointer;
        }
    }

    // .footercontent__menu__wrap {
    //     display: flex;
    //     gap: 3rem;
    // }

    .footercontent__title-bold,
    .footercontent--menu-bold h2 {
        font-weight: 500;
    }

    .footercontent--menu-light {
        font-weight: 200 !important;
    }

    .footercontent--menu-block {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    .footercontent__row {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        .footercontent--logo {
            display: flex;
            align-items: flex-start;
            img {
                width: 40%;
            }
        }

        .footercontent--contactdetails {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
        }

        .footercontent--menu {
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
        }

        .footercontent--button {
            button {
                text-align: center;
                border: 1px solid $white-color;
            }
        }
    }
}

// Phone mediaquery
@media (max-width: 575.98px) {
    .footer__content {
        padding: 0;
        .footer-mobile {
            display: flex;
            flex-direction: column;
            gap: 32px;
            margin: 40px 0 20px 0;

            h2 {
                color: white;
                font-weight: normal;
                font-size: 1rem;
            }

            .footer-pages {
                display: grid;
                grid-template-columns: 1fr 1fr;
                justify-items: center;
                .footer-pages__col {
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                }
            }

            .footer-about {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 25px;
                justify-items: center;
                align-items: center;

                .footer-logo {
                    display: flex;
                    justify-content: flex-end;
                    width: 100%;
                    img {
                        width: 125px;
                    }
                }
            }
        }
    }
}

// Table mediaquery
@media (min-width: 768px) and (max-width: 1024px) {
    .footercontent__container {
        .footercontent__row {
            .footercontent--logo {
                img {
                    width: 100% !important;
                }
            }
        }
    }
}
