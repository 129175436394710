$main-color: #00c3b2;
$second-color: #407580;
$black-color: #3b3b3b;
$gris-color: #ebebeb;
$red-color: #ef9090;
$yellow-color: #f7d588;
$white-color: #ffffff;
$main-padding: 3rem;
$main-padding-mobile: 1rem;
$main-padding-content: 3rem 5rem 6rem 5rem;
$main-gap: 3rem;
$title-color: #27535c;

:export {
  main-color: $main-color;
  second-color: $second-color;
  black-color: $black-color;
  gris-color: $gris-color;
  red-color: $red-color;
  yellow-color: $yellow-color;
  main-padding: $main-padding;
  main-padding-mobile: $main-padding-mobile;
  white-color: $white-color;
  main-gat: $main-gap;
}
