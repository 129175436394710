@import '../../../../sass/variables.scss';

.loader__container {
    display: flex;
    justify-content: center;
    margin: 2rem;
    .loader__icon {
        color: $black-color;
    }
}
