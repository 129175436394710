@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Junts';
  src: url('./assets/fonts/steradian/Steradian\ Black.otf') format('opentype');
  font-weight: 900;
}

@font-face {
  font-family: 'Junts';
  src: url('./assets/fonts/steradian/Steradian\ Bold.otf') format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: 'Junts';
  src: url('./assets/fonts/steradian/Steradian\ Medium.otf') format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'Junts';
  src: url('./assets/fonts/steradian/Steradian\ Regular.otf') format('opentype');
  font-weight: normal;
}

@font-face {
  font-family: 'Junts';
  src: url('./assets/fonts/steradian/Steradian\ Light.otf') format('opentype');
  font-weight: 300;
}
@font-face {
  font-family: 'Junts';
  src: url('./assets/fonts/steradian/Steradian\ ExtraLight.otf') format('opentype');
  font-weight: 200;
}

@font-face {
  font-family: 'Junts';
  src: url('./assets/fonts/steradian/Steradian\ Thin.otf') format('opentype');
  font-weight: 100;
}

body {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
code,
button,
li,
.swal2-html-container {
  font-family: 'Junts', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Junts', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  transition: all 0.3s ease-in-out;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  font-size: 14pt;
  text-decoration: none;
  color: #000000;
}

h1 {
  font-size: 55pt;
}

h2 {
  font-size: 40pt;
}

h3 {
  font-size: 30pt;
}

h4 {
  font-size: 28pt;
}

h2 {
  line-height: 1.2;
}

.noselect {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
