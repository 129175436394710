@import '../../../../sass/variables.scss';

.socialicons__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 15px;

    a {
        .socialicons__icon {
            background-color: $white-color;
            border-radius: 45%;
            padding: 0.25em;
            transition: all 0.3s;
            font-size: 1.25rem;
            color: $main-color;

            &:hover {
                background-color: transparent;
                color: $white-color;
                transform: scale(1.25);
                cursor: pointer;
            }
        }
    }
}

@media (max-width: 575.98px) {
    .socialicons__container {
    }
}
