@import '../../../sass/variables.scss';

.layout__container {
  position: relative;
}

.go-top-button {
  position: fixed;
  bottom: 1rem;
  right: 3rem;
  z-index: 999;
  .go-top-icon {
    font-size: 50px;
    color: #a1a1a1;
    border-radius: 50%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border: 2px solid #a1a1a1;
  }
}

@media (max-width: 575.98px) {
  .go-top-button {
    right: 1rem;
  }
}
