@import '/src/sass/variables.scss';

.menudropdown__container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .menudropdown__parent {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        // margin-bottom: 0.5rem;

        .menudropdown__parent--icon {
            transition: all 0.3s ease-in-out;
            font-size: 2rem;
            &:hover {
                color: $main-color;
                cursor: pointer;
                transform: scale(1.1);
            }
        }

        .menudropdown__parent--icon-active {
            color: $main-color;
            &:hover {
                color: $black-color;
            }
        }
    }

    .menudropdown__children {
        margin: 0.25rem 0;
    }
}
