@import '../../../../sass/variables.scss';

.afiliatbutton__container {
    .junts-button--primary {
        background-color: $main-color;
        color: $white-color;
    }

    .junts-button--secondary {
        color: $main-color;
        background-color: $white-color;
        border: 2px solid $main-color;
    }

    button {
        border-radius: 15px;
        padding: 10px 40px;
        transition: all 0.3s;
        font-size: 18px;
        display: flex;
        gap: 0.5rem;
        align-items: center;

        &:hover {
            transform: scale(1.05);
        }
    }
}
