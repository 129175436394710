@import '/src/sass/variables.scss';

.container {
  padding: 3rem 6rem;
}

.color-red {
  color: $red-color !important;
}

.color-title {
  color: $title-color !important;
}

.color-white {
  color: $white-color !important;
}

/* Space */
.no-margin {
  margin: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.w-25 {
  width: 25%;
}

.w-50 {
  width: 50%;
}

.w-75 {
  width: 75%;
}

.w-100 {
  width: 100%;
}

.w-auto {
  width: auto;
}

.h-100 {
  height: 100%;
}

.min-h-100 {
  min-height: 100%;
}

/* Text */
.text-light {
  font-weight: 300 !important;
}

.text-normal {
  font-weight: 400 !important;
}

.text-medium {
  font-weight: 500 !important;
}

.text-bolder {
  font-weight: 600 !important;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

/* Cursor */
.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.pointer-events-none {
  pointer-events: none;
}

.absolute-align-center {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}

.position-fixed {
  position: fixed;
}

.position-sticky {
  position: sticky;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.right-0 {
  right: 0;
}

.inset-0 {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.z-index-20 {
  z-index: 20;
}

.z-index-21 {
  z-index: 21;
}

/* Display */
.d-block {
  display: block;
}

.d-inline {
  display: inline;
}

.d-inline-block {
  display: inline-block;
}

.d-table {
  display: table;
}

.d-table-row {
  display: table-row;
}

.d-table-column {
  display: table-column;
}

.d-table-cell {
  display: table-cell;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

/* Flex */

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-align-items-start {
  align-items: flex-start;
}

.flex-align-items-center {
  align-items: center;
}

.flex-align-items-end {
  align-items: flex-end;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-around {
  justify-content: space-around;
}

.flex-justify-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-grow {
  flex-grow: 1;
}

.flex-no-shrink {
  flex-shrink: 0;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-gap-sm {
  gap: 0.75rem;
}

.flex-gap-md {
  gap: 1rem;
}

.flex-gap-lg {
  gap: 1.5rem;
}

.flex-gap-xl {
  gap: 3rem;
}

.scroll {
  white-space: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.overflow-y {
  overflow-y: auto;
}

// TABLET MEDIA QUERY
@media (min-width: 768px) and (max-width: 1024px) {
  .container {
    padding: 3rem 1rem;
  }
}

// PHONE MEDIA QUERY
@media (max-width: 575.98px) {
  .container {
    padding: 3rem 1rem;
  }
}
