@import './sass/buttons.scss';
@import './sass/variables.scss';

.swal2-shown,
.swal2-height-auto {
  padding: 0 !important;
  // overflow: hidden;
}

.swal2-close {
  width: 32px;
  height: 32px;
}

.leaflet-control-attribution {
  display: none;
}

.flickr-embed .flickr-embed-photo {
  background: white !important;
}

.title__section {
  margin: 50px 0px !important;
  h2 {
    font-size: 2rem;
    color: $title-color;
  }
}

.main__content__padding {
  padding: $main-padding-content;
}

section {
  margin-top: 50px;
  padding-top: 50px;
  &:first-child {
    margin-top: 0;
    padding-top: 0;
  }
}

h2 {
  font-size: 1.25rem;
  color: $title-color;
}

p {
  font-weight: 300;
  color: $title-color;
  line-height: 1.5;
  font-size: 14pt;
  margin: 25px 0px;
}

button {
  background-color: $red-color;
  border-radius: 5px;
  border: none;
  color: #fff;
  font-size: 1.25rem;
  font-weight: bold;
  padding: 10px 20px;
  &:hover {
    background-color: $black-color;
    color: #fff;
    cursor: pointer;
  }

  &:disabled {
    background-color: #606060;
    cursor: initial;
  }
}

.reacthookform__container {
  form {
    .form__title {
      margin: 20px 0;
      &:first-child {
        margin: 0px;
        margin-bottom: 20px;
      }
    }

    display: flex;
    flex-direction: column;
    width: 50%;

    .legal__box {
      display: flex;
      align-items: center;

      .inputfield {
        width: 10%;
        margin-right: 20px;
        .css-1a1fmpi-MuiInputBase-root-MuiInput-root:before {
          border-bottom: none !important ;
        }
      }

      p {
        width: 90%;
        font-size: 0.85rem;
      }
    }

    .inputfield {
      width: 100%;
      margin-bottom: 10px;

      input[type='file'] {
        display: none;
      }

      .file__selector__input {
        &:before {
          // background-color: $four-purple-color;
          align-items: center;
          border-radius: 3px;
          bottom: 0;
          color: #606060;
          content: 'Seleccionar arxiu';
          display: flex;
          font-size: 15px;
          font-size: 0.75rem;
          font-family: 'Junts', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
            'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
          font-weight: 400;
          justify-content: center;
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
        }
        input {
          display: inline-block;
          opacity: 0;
          width: rem;
        }
      }
    }

    button {
      margin-top: 20px;
    }
  }
}

ol {
  list-style-type: disc;
  li {
    margin: 3rem;
    line-height: 1.5;
    font-size: 1.25rem;
  }
}

.rendered__html {
  margin: 0;
  margin-bottom: 0;
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  span,
  ul,
  ol,
  li {
    font-family: 'Junts' !important;
  }
}

.blocks-gallery-item {
  img {
    width: 50%;
    height: auto;
    margin: 1rem 0;
  }
}

.barmenu__link {
  border-bottom: 2px solid transparent;
  color: #fff;
  font-weight: bold;
  margin-bottom: -5px;
  padding-bottom: 5px;
  transition: all 0.3s;
  font-size: 1.5rem;

  &:hover {
    border-bottom: 2px solid $title-color;
    color: $title-color;
    cursor: pointer;
  }
}

.swal2-container {
  z-index: 9999999999999 !important;
  padding: 0;

  .swal2-close {
    background-color: $main-color;
    border-radius: 50%;
    color: $white-color;
    position: absolute;
    left: 1rem;
    top: 1rem;
    transition: all 0.3s;
    &:hover {
      color: $white-color;
      background-color: $title-color;
    }
  }

  .swal2-popup {
    display: flex;
    align-items: center;
  }
}

// **
// **
// MEDIAQUERY PHONE
@media (max-width: 575.98px) {
  section {
    margin: 0;
    padding-top: 1rem;
    &:first-child {
      margin: 0;
    }
  }

  ol {
    li {
      margin: 1rem;
      line-height: 1.5;
      font-size: 1.25rem;
    }
  }

  .reacthookform__container {
    form {
      width: 100%;
    }
  }

  .blocks-gallery-item {
    img {
      width: 100%;
      height: auto;
    }
  }

  .title__section {
    margin: 1rem !important;
    margin-left: 0 !important;
  }

  .main__content__padding {
    padding: $main-padding-mobile !important;
  }

  .swal2-container {
    padding: 0 !important;
    .swal2-close {
      margin-bottom: 1rem;
      &:hover {
        color: #fff !important;
      }
    }
  }

  .componentmodal__popup {
    padding: 1rem !important;
    width: 100% !important;
  }
}

// **
// **
// MEDIAQUERY TABLET
@media (min-width: 768px) and (max-width: 1024px) {
  section {
    margin: 3rem 0;
    padding: 0;
    &:first-child {
      margin: 0;
    }
  }

  .main__content__padding {
    padding: $main-padding-mobile;
  }

  .componentmodal__popup {
    padding: 1rem !important;
    width: 100% !important;
  }
}
