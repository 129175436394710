@import '/src/sass/variables.scss';

.legal__container {
  padding: 6rem 3rem;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  gap: 3rem;

  h1 {
    font-size: 3rem;
    color: $title-color;
    margin-bottom: 1.5rem;
  }

  h2 {
    font-size: 2rem;
    color: $title-color;
    margin-bottom: 1.5rem;
  }

  h3 {
    font-size: 1.5rem;
    color: $title-color;
    margin-bottom: 1.5rem;
  }

  p,
  li,
  ul {
    font-size: 1rem;
    font-weight: 400;
    color: $title-color;
    margin-bottom: 1.5rem;
  }
}

// Tablet query
@media (min-width: 768px) and (max-width: 1024px) {
  .legal__container {
    padding: 3rem 1rem;
  }
}

// Phone query
@media (max-width: 575.98px) {
  .legal__container {
    padding: 3rem 1rem;
  }
}
