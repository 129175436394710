@import '../../../../../sass/variables.scss';

.header__container {
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  width: 100%;
  transition: all 0.3s;
  z-index: 999999999;
  box-shadow: 0px 2px 8px 0px #00000033;

  .header__socialbar {
    background-color: $main-color;
    padding: 10px $main-padding;
    display: flex;
    justify-content: flex-end;
    transition: all 0.3s;
  }

  .header__elements {
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 0.3s;

    .header__elements__left {
      .header__elements__left--logo {
        svg {
          width: 90px;
        }
      }
    }

    .header__elements__right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: $main-gap;

      .menu__icon {
        font-size: 50px;
        color: $main-color;
        transition: all 0.3s;
        &:hover {
          color: $black-color !important;
          cursor: pointer;
        }
      }
    }
  }
}

/************************/
/************************/
/* PHONE MEDIA QUERIES */
@media (max-width: 575.98px) {
  .header__container {
    .header__socialbar {
      display: none;
    }

    .header__elements {
      padding: $main-padding-mobile;
      .header__elements__right {
        .header__elements__rigth--barmenu {
          display: none;
        }

        .header__elements__buttons {
          display: none;
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .header__container {
    .header__socialbar {
      display: none;
    }

    .header__elements {
      padding: $main-padding-mobile;
      .header__elements__right {
        .header__elements__buttons {
          display: none;
        }
      }
    }
  }
}
