@import '/src/sass/variables.scss';

.btn-chromeless {
  background-color: initial;
  padding: 0;
  margin: 0;

  &:hover {
    background-color: initial;
  }
}

.button__back {
  margin-top: 3rem;
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
  align-items: center;
  cursor: pointer;

  &:hover {
    .button__back--icon,
    h2 {
      transition: all 0.3s;
      color: $main-color;
    }
  }

  .territori__back--icon {
    color: $title-color;
    // border: 1px solid $title-color;
    // border-radius: 50%;
  }

  h2 {
    font-weight: 400;
    font-size: 1.5rem;
  }
}

.load-more-button {
  background-color: transparent;
  color: $title-color;
  border-bottom: 1px solid #bacdd2;
  border-radius: 0;
  text-transform: uppercase;
  font-weight: 300;

  &:hover {
    color: $main-color;
    border-color: $main-color;
    background-color: transparent;
  }
}

@media (max-width: 575.98px) {
  .button__back {
    margin-top: 3rem !important;
    padding: 0rem;
  }
}
