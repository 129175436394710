@import '/src/sass/variables.scss';

.donations-done {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  justify-content: center;
  padding: 3rem;
}

.donations-done-box {
  box-shadow: 2px 3px 8px #00000026;
  width: 600px;
}

.donations-done-box__header {
  background-color: $main-color;
  padding: 1rem;
  h2 {
    color: $white-color;
    font-size: 3rem;
    text-align: center;
  }
}

.donations-done-box__body {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}

.donations-done-box__banner {
  max-width: 600px;

  img {
    width: 100%;
  }
}
